var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _vm.isNewPlan
          ? _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [
                _c("c-card", {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "요청된 작업이 없습니다." },
                }),
              ],
              1
            )
          : _vm._e(),
        !_vm.isNewPlan
          ? _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { title: "작업요청 상세" },
                  },
                  [
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-equip", {
                            attrs: {
                              required: true,
                              editable: false,
                              label: "설비Tag",
                              name: "equipmentCd",
                            },
                            model: {
                              value: _vm.data.equipmentCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "equipmentCd", $$v)
                              },
                              expression: "data.equipmentCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-func-location", {
                            attrs: {
                              type: "edit",
                              plantCd: _vm.data.plantCd,
                              editable: false,
                              label: "기능위치",
                              name: "funcLocationCd",
                            },
                            model: {
                              value: _vm.data.funcLocationCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "funcLocationCd", $$v)
                              },
                              expression: "data.funcLocationCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-cost-center", {
                            attrs: {
                              type: "edit",
                              plantCd: _vm.data.plantCd,
                              editable: false,
                              label: "코스트센터",
                              name: "costCenterCd",
                            },
                            model: {
                              value: _vm.data.costCenterCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "costCenterCd", $$v)
                              },
                              expression: "data.costCenterCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-select", {
                            attrs: {
                              required: true,
                              editable: false,
                              codeGroupCd: "WO_WORK_TYPE_CD",
                              type: "edit",
                              itemText: "codeName",
                              itemValue: "code",
                              name: "woWorkTypeCd",
                              label: "작업유형",
                            },
                            model: {
                              value: _vm.data.woWorkTypeCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "woWorkTypeCd", $$v)
                              },
                              expression: "data.woWorkTypeCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              required: true,
                              editable: false,
                              label: "작업명",
                              name: "woRequestName",
                            },
                            model: {
                              value: _vm.data.woRequestName,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "woRequestName", $$v)
                              },
                              expression: "data.woRequestName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-datepicker", {
                            attrs: {
                              required: true,
                              editable: false,
                              default: "today",
                              label: "요청일",
                              name: "woRequestDt",
                            },
                            model: {
                              value: _vm.data.woRequestDt,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "woRequestDt", $$v)
                              },
                              expression: "data.woRequestDt",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-dept", {
                            attrs: {
                              type: "edit",
                              editable: false,
                              label: "작업요청 부서",
                              required: true,
                              name: "deptCd",
                            },
                            model: {
                              value: _vm.data.deptCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "deptCd", $$v)
                              },
                              expression: "data.deptCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-field", {
                            attrs: {
                              required: true,
                              editable: false,
                              data: _vm.data,
                              type: "user",
                              label: "작업요청인",
                              name: "userId",
                            },
                            model: {
                              value: _vm.data.userId,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "userId", $$v)
                              },
                              expression: "data.userId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-plant", {
                            attrs: {
                              required: true,
                              editable: false,
                              name: "plantCd",
                            },
                            model: {
                              value: _vm.data.plantCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "plantCd", $$v)
                              },
                              expression: "data.plantCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-checkbox", {
                            attrs: {
                              editable: false,
                              isFlag: true,
                              label: "긴급작업",
                              name: "emergencyFlag",
                            },
                            model: {
                              value: _vm.data.emergencyFlag,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "emergencyFlag", $$v)
                              },
                              expression: "data.emergencyFlag",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: false,
                              label: "긴급작업 사유",
                              name: "emergencyReason",
                            },
                            model: {
                              value: _vm.data.emergencyReason,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "emergencyReason", $$v)
                              },
                              expression: "data.emergencyReason",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: false,
                              label: "MOC번호",
                              name: "mocId",
                            },
                            model: {
                              value: _vm.data.mocId,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "mocId", $$v)
                              },
                              expression: "data.mocId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: false,
                              label: "MOC 명칭",
                              name: "mocName",
                            },
                            model: {
                              value: _vm.data.mocName,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "mocName", $$v)
                              },
                              expression: "data.mocName",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.param.woWorkPlanStepCd == "WWPSC99998"
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                            },
                            [
                              _c("c-text", {
                                attrs: {
                                  editable: false,
                                  label: "요청 반려자",
                                  name: "chgUserName",
                                },
                                model: {
                                  value: _vm.data.chgUserName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "chgUserName", $$v)
                                  },
                                  expression: "data.chgUserName",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.param.woWorkPlanStepCd == "WWPSC99998"
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                            },
                            [
                              _c("c-text", {
                                attrs: {
                                  editable: false,
                                  label: "요청 반려일",
                                  name: "chgDtStr",
                                },
                                model: {
                                  value: _vm.data.chgDtStr,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "chgDtStr", $$v)
                                  },
                                  expression: "data.chgDtStr",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8",
                        },
                        [
                          _c("c-textarea", {
                            attrs: {
                              editable: false,
                              rows: 7,
                              label: "작업요청 세부사항",
                              name: "remark",
                            },
                            model: {
                              value: _vm.data.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "remark", $$v)
                              },
                              expression: "data.remark",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                        },
                        [
                          _c("c-table", {
                            ref: "grid",
                            attrs: {
                              title: "정비오더 발행부서",
                              columns: _vm.grid.columns,
                              data: _vm.grid.data,
                              gridHeight: _vm.grid.height,
                              isTitle: true,
                              editable: false,
                              hideBottom: true,
                              isExcelDown: false,
                              filtering: false,
                              isFullScreen: false,
                              columnSetting: false,
                              selection: "multiple",
                              rowKey: "deptCd",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            )
          : _vm._e(),
        !_vm.isNewPlan
          ? _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                staticStyle: { "padding-top": "25px !important" },
              },
              [
                _c("c-upload", {
                  attrs: {
                    attachInfo: _vm.attachInfo,
                    editable: false,
                    label: "첨부파일",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c(
        "q-dialog",
        {
          attrs: { persistent: "" },
          model: {
            value: _vm.prompt,
            callback: function ($$v) {
              _vm.prompt = $$v
            },
            expression: "prompt",
          },
        },
        [
          _c(
            "q-card",
            { staticStyle: { "min-width": "350px" } },
            [
              _c("q-card-section", [
                _c("div", { staticClass: "text-h6" }, [
                  _vm._v("반려 사유 입력"),
                ]),
              ]),
              _c(
                "q-card-section",
                { staticClass: "q-pt-none" },
                [
                  _c("q-input", {
                    attrs: { dense: "", autofocus: "" },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.setReturnReason.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.refusalReason,
                      callback: function ($$v) {
                        _vm.refusalReason = $$v
                      },
                      expression: "refusalReason",
                    },
                  }),
                ],
                1
              ),
              _c(
                "q-card-actions",
                { staticClass: "text-primary", attrs: { align: "right" } },
                [
                  _c("q-btn", {
                    attrs: { flat: "", label: "취소" },
                    on: { click: _vm.cancelReturnReason },
                  }),
                  _c("q-btn", {
                    attrs: { flat: "", label: "확인" },
                    on: { click: _vm.setReturnReason },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "popup-bottom-bar" }, [
        _c(
          "div",
          { staticClass: "popup-bottom-bar-btngroup" },
          [
            _c(
              "q-btn-group",
              { attrs: { outline: "" } },
              [
                _vm.param.woRequestId &&
                _vm.param.woWorkPlanStepCd == "WWPSC00000"
                  ? _c("c-btn", {
                      attrs: {
                        label: "반려",
                        editable: _vm.editable,
                        icon: "delete_forever",
                      },
                      on: { btnClicked: _vm.addReturnReason },
                    })
                  : _vm._e(),
                _vm.param.woRequestId &&
                _vm.param.woWorkPlanStepCd == "WWPSC00000"
                  ? _c("c-btn", {
                      attrs: {
                        label: "승인",
                        editable: _vm.editable,
                        icon: "save",
                      },
                      on: { btnClicked: _vm.savePlan },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "popup-bottom-bar-close" },
          [
            _c("q-btn", {
              attrs: { flat: "", color: "gray", icon: "arrow_back" },
              on: { click: _vm.closePopUps },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }